<template>
  <div class="user-info-controller" :class="isMeClasses">
    <div class="user-info-controller__content">
      <div class="user-info-controller__info">
        <div v-if="!currentUser?.isBot && isCurrentUser" class="user-info-controller__info-id">
          ID: {{ currentUser?.userId }}
        </div>
        <ProfileUserAvatarContainer
          class="user-info-controller__info-avatar"
          :status="generateLevelStatus"
          :avatar-src="currentUser?.avatar!"
          :user-level="Number(currentUser?.userLevel ?? 0)"
          :current-experience="Number(currentUser?.currentExp ?? 0)"
          :experience-to-next-level="Number(currentUser?.expToNextLevel ?? 0)"
          :was-generated-level="currentUser?.wasGeneratedLevel"
          @generate-level="handleGenerateLevel"
          @open-exp-popup="isExpPopupOpen = true"
        />
        <div
          class="user-info-controller__info-right"
          :class="{ 'user-info-controller__info-right--bot': currentUser?.isBot }"
        >
          <SvgoSteamIcon
            v-if="!currentUser?.isBot && currentUser?.steam?.id"
            class="user-info-controller__info-icon"
            :width="iconSize"
            :height="iconSize"
            @click="openSteamProfile()"
          />
          <SvgoBotIcon v-else-if="currentUser?.isBot" :width="iconSize" :height="iconSize" />
        </div>
      </div>
      <ProfileUserActionWrapper :is-me="isCurrentUser" :user-name="currentUser?.nickname" />
      <BattlesPopupWrapper v-model:is-open="isExpPopupOpen">
        <PopupInfoUserExp :timer="eventStore.eventTimerUnixTime" @on-close-click="isExpPopupOpen = false" />
      </BattlesPopupWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import PopupInfoUserExp from '../../widgets/PopupInfo/PopupInfoUserExp.vue';
import { useUserStore } from '~/store/user/user.store';
import { ProfileUserEvents } from '~/repository/amplitude/events/profile/profile-user';
import { EProfileType } from '~/repository/amplitude/types/profile/profile-common';
import { EUserAvatarContainerStatus } from '~/features/profile/components/UserAvatarContainer/UserAvatarContainer.types';
import { useEventStore } from '~/store/event/event.store';
import { useGenerateUserLevelStore } from '~/store/generateUserLevel/generateUserLevel.store';
import { useAlertStore } from '~/store/alert/alert.store';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const iconSize = computed(() => {
  return changeXL(32, 20);
});

const { t } = useI18n();

const alertStore = useAlertStore();
const generateLevelStore = useGenerateUserLevelStore();
const eventStore = useEventStore();
const userStore = useUserStore();
const { currentUser, isCurrentUser } = storeToRefs(userStore);

const isExpPopupOpen = ref<boolean>(false);

const isMeClasses = computed(() => ({
  [`user-info-controller--me`]: isCurrentUser.value,
}));

const openSteamProfile = () => {
  const steamId = currentUser.value?.steam?.id;
  if (steamId) {
    const steamProfileUrl = `https://steamcommunity.com/profiles/${steamId}`;
    window.open(steamProfileUrl, '_blank');

    ProfileUserEvents.steamLinkClicked({
      'Profile Type': isCurrentUser.value ? EProfileType.PERSONAL : EProfileType.FOREIGN,
    });
  }
};

const generateLevelStatus = ref<EUserAvatarContainerStatus>(
  generateLevelStore.isReadyToGenerate
    ? EUserAvatarContainerStatus.READY_TO_GENERATE
    : EUserAvatarContainerStatus.DEFAULT,
);

async function handleGenerateLevel() {
  if (generateLevelStatus.value !== EUserAvatarContainerStatus.READY_TO_GENERATE) {
    return;
  }
  const prevStatus = generateLevelStatus.value;

  generateLevelStatus.value = EUserAvatarContainerStatus.GENERATING;

  const result = await generateLevelStore.generateLevel();

  if (result) {
    generateLevelStatus.value = EUserAvatarContainerStatus.DONE_GENERATING;
    setTimeout(() => {
      generateLevelStatus.value = EUserAvatarContainerStatus.DEFAULT;
    }, 2000);
  } else {
    generateLevelStatus.value = prevStatus;
  }

  alertStore.showError({
    message: t('mainPage.dailyCases.generationError.message'),
    title: t('mainPage.dailyCases.generationError.title'),
  });
}
</script>

<style src="./UserInfoController.style.scss" scoped lang="scss" />
