import type { IGetSatDashboardParams } from '~/repository/modules/profile/types/statistics';
import { useAlertStore } from '~/store/alert/alert.store';
import type { IStatGeneralState } from '~/features/profile/store/statGeneral/StatGeneral.types';

export const useStatGeneralStore = defineStore('profile/statGeneral', () => {
  const { $api } = useNuxtApp();
  const route = useRoute();
  const alertStore = useAlertStore();

  const isLoaded = ref(false);
  const data = ref<Partial<IStatGeneralState>>({});

  async function fetch() {
    try {
      isLoaded.value = false;
      const params: IGetSatDashboardParams = {
        userId: Number(route.params.userId),
      };

      const response = await $api.profile.getStatDashboard(params);
      if (!response) return;
      data.value = response;
    } catch (e) {
      if (typeof e === 'string') {
        alertStore.showError({
          title: e,
        });
      }
    } finally {
      isLoaded.value = true;
    }
  }

  return { fetch, isLoaded, data };
});
