<template>
  <div class="user-avatar-experience">
    {{ displayExperience }}
    <SvgoInfo v-if="canOpenPopup" :width="12" :height="12" @click="$emit('open-exp-popup')" />
  </div>
</template>

<script setup lang="ts">
import type { IUserAvatarExperienceEmits, IUserAvatarExperienceProps } from './UserAvatarExperience.types';

const props = defineProps<IUserAvatarExperienceProps>();
defineEmits<IUserAvatarExperienceEmits>();

const displayExperience = computed<`${string} / ${string} XP`>(() => {
  return `${Math.max(0, props.currentExperience)} / ${Math.max(0, props.experienceToNextLevel)} XP`;
});
</script>

<style scoped lang="scss" src="./UserAvatarExperience.scss"></style>
