<template>
  <div class="link-component-container save-link-component">
    <div class="save-link-component__top">
      <div class="save-link-component__tooltip">
        <SharedText class="save-link-component__tooltip-title">{{ actualType.title }}</SharedText>
        <SharedKitTooltip position="top" @mouseenter="handleTooltipHover">
          {{ actualType.tooltipText }}
        </SharedKitTooltip>
        <SharedText v-if="changeXL(false, true)" class="save-link-component__tooltip-text">
          {{ actualType.tooltipText }}
        </SharedText>
      </div>
      <SvgoCloseIcon class="save-link-component__top-icon" @click="handleCancel" />
    </div>
    <div class="save-link-component__bottom">
      <PaymentInputField v-model:value="inputModel" :error="errorText" :placeholder="actualType.placeholder" />
      <SharedKitButton
        :is-disabled="!inputModel"
        :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
        :size-preset="actionButtonsSize"
        :type-preset="TypePresets.RECTANGLE"
        :text="$t('profile.steamTradeWidget.save')"
        @click="handleSave"
      />
    </div>
    <SharedText
      align="center"
      class="save-link-component__text"
      :target="LinkTarget.LINK_BLANK"
      :to="actualType.whereLink"
      @click="handleWhereLink"
    >
      {{ $t('profile.saveLink.whereTakeLinkText') }}
    </SharedText>
  </div>
</template>

<script setup lang="ts">
import { ProfileSteamWidgetLinks } from '../SteamTradeWidget.data';
import type {
  ISaveLinkComponentProps,
  IEmitSaveLinkComponent,
  ISaveLinkComponentData,
} from './SaveLinkComponent.types';
import { BattleColorPresets } from '@/features/battles/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { LinkTarget } from '~/constants/attributes';
import { ProfileAdditionalEvents } from '~/repository/amplitude/events/profile/profile-additional';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<ISaveLinkComponentProps>();
const { link, type, errorText } = toRefs(props);

const emit = defineEmits<IEmitSaveLinkComponent>();

const actionButtonsSize = computed(() => changeXL(SizePresets.M, SizePresets.S));

const inputModel = ref(type.value === 'login' ? link.value.login : link.value.trade);
watch(inputModel, (newValue) => {
  if (type.value === 'login') {
    link.value.login = newValue;
  } else {
    link.value.trade = newValue;
  }
});

const { t } = useI18n();

const actualType = computed<ISaveLinkComponentData>(() => {
  switch (type.value) {
    case 'trade':
      return {
        placeholder: t('profile.saveLink.tradeLinkSteamPlaceholder'),
        title: t('profile.saveLink.tradeLinkSteamTitle'),
        tooltipText: t('profile.saveLink.tradeLinkSteamTooltip'),
        whereLink: ProfileSteamWidgetLinks.TRADE,
      };
    case 'login':
      return {
        placeholder: t('profile.saveLink.loginSteamPlaceholder'),
        title: t('profile.saveLink.loginSteamTitle'),
        tooltipText: t('profile.saveLink.loginSteamTooltip'),
        whereLink: ProfileSteamWidgetLinks.LOGIN,
      };
    default:
      return {
        placeholder: '',
        title: '',
        tooltipText: '',
        whereLink: '',
      };
  }
});

const handleSave = () => {
  type.value === 'trade' ? ProfileAdditionalEvents.tradeLinkSaved() : ProfileAdditionalEvents.steamLoginSaved();
  emit('onLinkSave', {
    link: inputModel.value ?? '',
    type: type.value,
  });
};

const handleCancel = () => {
  emit('onCancel');
};

const handleTooltipHover = () => {
  type.value === 'trade'
    ? ProfileAdditionalEvents.tradeLinkInstructionClicked()
    : ProfileAdditionalEvents.steamLoginInstructionClicked();
};

const handleWhereLink = () => {
  type.value === 'trade' ? ProfileAdditionalEvents.tradeLinkReceived() : ProfileAdditionalEvents.steamLoginReceived();
};
</script>

<style src="./SaveLinkComponent.style.scss" scoped lang="scss" />
